import * as React from "react"
import '../../gatsby-browser.js';
import Into from "../static/img/intro.png";
import Navbar from "../component/Navbar/Navbar";
import Footer from "../component/Footer/Footer";
import HowAbout from "../component/Howwedo/HowAbout"
import HowTech from "../component/Howwedo/HowTech";
import { motion } from "framer-motion"
import Aboutus from "../component/About/Aboutus";
import axios from "axios";
import {url} from "../url/url";
import { lan } from "../lan/index.js";
import Mission from "../component/About/Mission";
import Vision from "../component/About/Vision";
import MetaTags from "../component/MetaTags.jsx";
import Load from '../static/img/load/load.gif'


class About extends React.Component{
    constructor(props) {
        super(props);

        this.state={
            data:[]
        }
    }

    // componentDidMount() {

    //     if(typeof window !== 'undefined') {
    //         axios({
    //             method: 'GET',
    //             url: url + '/abouts?_locale=' + localStorage.getItem('lan'),
    //         })
    //             .then((result) => {
    //                 this.setState({
    //                     data: result.data,
    //                     loading: false
    //                 })
    //             })
    //             .catch((error) => {
    //                 this.setState({
    //                     loading: false
    //                 })
    //             })
    //     } else if(typeof window == 'undefined') {
    //         axios({
    //             method: 'GET',
    //             url: url + '/abouts?_locale=' + 'en',
    //         })
    //             .then((result) => {
    //                 this.setState({
    //                     data: result.data,
    //                     loading: false
    //                 })
    //             })
    //             .catch((error) => {
    //                 this.setState({
    //                     loading: false
    //                 })
    //             })
    //     }


    // }

    render(){
        const pageVariants = {
            initial: {
                opacity: 0,
            },
            in: {
                opacity: 1,
            },
            out: {
                opacity: 0,
            },
        }

       const data = lan.en;
        return(
            <>

                <Navbar />

                <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                >

                    <section id="howwedo" className="howwedo">
                
                                <Aboutus value={data} />
                                <Mission value={data.mission} />
                                <Vision value={data.vision} />
                    </section>

                      

                     
                    <Footer value={data} />

                </motion.div>
                <MetaTags title={"About | Koderbox"} description={data.info?.[2]} />
            </>
        )
    }
}

export default About