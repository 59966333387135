import * as React from "react";
import "../../../gatsby-browser";

import Soft from "../../static/img/soft.png";
import { url } from "../../url/url";
import Load from "../../static/img/load/load.gif";
// import ReactHtmlParser from "react-html-parser";

class Mission extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  render() {
    return (
      <>
        <section id="services" className="services">
          <header className="section-header">
            <h3 className="highlighted-text">
              Our <span className="light-text"> Mission</span>
            </h3>
          </header>

          <div className="whatservice">
            <p className="description">
              <p className="mission-body">{this.props.value}</p>
            </p>
          </div>
        </section>
      </>
    );
  }
}

export default Mission;
