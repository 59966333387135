import * as React from "react"
import '../../../gatsby-browser';

import Abouts from '../../static/img/about-img.svg'
import Soft from "../../static/img/soft.png";
import Web from "../../static/img/web.png";
import Mobile from "../../static/img/mobile.png";
import Seo from "../../static/img/seo.png"
import UI from "../../static/img/ui.png";
import WebDesign from "../../static/img/webdesign.png"
import Devops from "../../static/img/devop.png"
import axios from "axios";
import {url} from "../../url/url";
import Load from "../../static/img/load/load.gif";




class HowTech extends React.Component{
    constructor(props) {
        super(props);

        this.state={
            data:[],
            loading: true
        }
    }

    componentDidMount() {
        axios({
            method:'GET',
            url: url+'/technologies',
        })
            .then((result) => {
                this.setState({
                    data: result.data,
                    loading: false
                })
            })
            .catch((error) => {
                this.setState({
                    loading: false
                })
            })
    }

    render(){

        const { data, loading } = this.state;

        // console.log(data)

        return(
            <>


                {/* <section id="services" className="section-bg">
                    <div className="container">
                        <header className="section-header">
                            <h3>Technology We Work On</h3>
                            <p>
                                <hr />
                            </p>
                        </header>
                        <div className="row">

                            {loading ? (
                                <>
                                    <div align="center" className="mt-5 mb-5">
                                        <img src={Load} />
                                    </div>
                                </>
                            ) : (
                                <>

                                    {data.map((item, index) => {
                                        return (
                                            <>
                                                <div className="col-md-3 wow bounceInUp" data-wow-duration="1.4s">
                                                    <div className="box whatservice">
                                                        <div align="center">
                                                            <img src={url+item.image.url} alt="" className="img-fluid" width="100" />
                                                        </div> <br/>
                                                        <div align="center">
                                                            <h4 className="tech_head">{item.name}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}

                                </>
                            )}

                        </div>
                    </div>
                </section> */}


            </>
        )
    }
}

export default HowTech