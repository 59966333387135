import * as React from "react"
import '../../../gatsby-browser';

import Services from '../../static/img/img/ourwork.png'
import Load from "../../static/img/load/load.gif";
// import ReactHtmlParser from "react-html-parser";

class HowAbout extends React.Component{
    constructor(props) {
        super(props);

        this.state={
            data:[]
        }
    }

    render(){

        const data = this.props.value[0];

        return(
            <>

                {/* <section id="about">
                    <div className="container">
                        <div className="row about-extra">
                            <div className="col-lg-6 wow fadeInUp">
                                <img src={Services} className="img-fluid" alt="" width="400" />
                            </div>
                            <div className="col-lg-6 wow fadeInUp pt-5 pt-lg-0">
                                <h2>How We Do</h2>
                                <p>

                                    {data == undefined ? (
                                        <>
                                            <div align="center" className="mt-5 mb-5">
                                                <img src={Load} />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {ReactHtmlParser(data.content)}
                                        </>
                                    )}

                                </p>
                            </div>
                        </div>
                    </div>
                </section> */}

            </>
        )
    }
}

export default HowAbout