import * as React from "react";
import "../../../gatsby-browser";
import Services from "../../static/img/img/work.png";
import axios from "axios";
import { url } from "../../url/url";
import Load from "../../static/img/load/load.gif";
// import ReactHtmlParser from "react-html-parser";
import Illustration2 from "../../static/img/AboutPage/illustartion2.svg";

class Vision extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };
  }

  render() {
    return (
      <>
        <section id="about">
          <div className="row ">
            <header className="section-header">
              <h3 className="text-center highlighted-text">
                Our <span className="light-text">Vision</span>
              </h3>
            </header>

            <div className="vision-body">
              <p className="vision-desc">{this.props.value}</p>
              <img src={Illustration2} alt="" />
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Vision;
