import * as React from "react";
import "../../../gatsby-browser";
// import Services from "../../static/img/img/work.png";
// import axios from "axios";
// import { url } from "../../url/url";
// import Load from "../../static/img/load/load.gif";
// import ReactHtmlParser from "react-html-parser";
import Illustration1 from "../../static/img/AboutPage/illustartion1.svg";

class Aboutus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  render() {
    const data = this.props.value;
    return (
      <>
        <section id="about">
            <h1 align="center" className="light-text">About <span className="highlighted-text">Us</span></h1>
          <div className="container">
            <div className="row about-extra">
              <div className="col-lg-6 wow fadeInUp illustration-container">
                <img
                  src={Illustration1}
                  className="img-fluid illustration-img"
                  alt=""
                  width="400"
                />
              </div>
              <div className="col-lg-6 wow fadeInUp pt-5 pt-lg-0">
                <p className="about-body">
                  {data?.about[2]}
                </p>
                <p className="about-body">{data?.info[2]}</p>
                <p className="about-body">{data?.info[4]}</p>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Aboutus;
